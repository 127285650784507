.container-fluid {
  padding-top: 15px;
  padding-bottom: 30px;
}

.footer {
  height: 30px;
  line-height: 30px;
  background-color: #f5f5f5;
  padding-right: 15px;
  padding-left: 15px;
}
